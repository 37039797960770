export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp()

  if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered)
    return

  const { $i18n } = nuxtApp

  const token = useCookie('token') // get token from cookies

  const authenticated = await useAuthenticated()

  const userdata: any = useUserData()

  // Kiem tra lai neu co token auth
  if (token.value) {
    // check if value exists
    // todo verify if token is valid, before updating the state
    const host = await useGetHost()

    const data: any = await $fetch('/api/userdata', {
      headers: { token: token.value, domain: host, locale: $i18n.locale.value },
    })

    if (!data) {
      token.value = null
      authenticated.value = false
      userdata.value = {}
    }
    else {
      authenticated.value = true // update the state to authenticated
      userdata.value = data ?? {}
    }
  }
  else {
    // if token doesn't exist redirect to log in
    token.value = null
    authenticated.value = false
    userdata.value = {}
  }

  if (!token.value || !authenticated.value) {
    abortNavigation()

    const url = useRequestURL()

    return navigateTo({ path: $i18n.locale.value === $i18n.defaultLocale ? '/login' : `/${$i18n.locale.value}/login`, query: { redirect: url.href } }, { external: true })
  }
})
